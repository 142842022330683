












import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import SelectArea from "@/components/SelectArea/SelectArea.vue";
import { NavBar } from "vant";

declare type ProductSource = "POLY" | "DM" | "MY" | "MH" | "XY";

@Component({
  components: { SelectArea, [NavBar.name]: NavBar },
})
export default class SelectAreaPage extends Mixins(Mixin) {
  productSource: ProductSource = "POLY";

  mounted(): void {
    let productSource = this.$route.query.productSource;
    this.productSource = productSource
      ? (productSource as ProductSource)
      : "POLY";
    // 重置并更新数据
    let selectArea = this.$refs["select-area"] as SelectArea;
    if (selectArea) {
      selectArea.refreshData();
    }
  }

  selectSection({
    showId,
    productId,
    sectionId,
  }: {
    showId: number;
    productId: string;
    sectionId: string;
  }): void {
    this.$router.push(
      `/select-seat?showId=${showId}&productId=${productId}&sectionId=${sectionId}`
    );
  }

  goBackTo(): void {
    this.$router.go(-1);
  }
}
