








import { Component, Mixins, Vue } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import SelectSeat from "@/components/SelectSeat/SelectSeat.vue";
import { NavBar } from "vant";

@Component({
  components: {
    SelectSeat,
    [NavBar.name]: NavBar,
  },
})
export default class SelectSeatPage extends Mixins(Mixin) {
  public isClick = false;
  mounted(): void {
    // 第一时间重置历史数据
    this.resetData();
    this.InterfaceCacheModule.getCacheProductIds(() => {
      // 设置用户还能选多少座位
      let showId = Number(this.$route.query.showId);
      if (isNaN(showId)) {
        console.error("isNaN(Number(this.$route.query.showId))");
      } else {
        // 如果有缓存的预订单 UUID，则先删除该预订单中锁定的座位
        let orderUuid = this.DeleteLockSeatModule.orderUuid;
        if (orderUuid) {
          this.DeleteLockSeatModule.SET_orderUuid_PERSIST("");
          this.$api.orderApi.ticketOrder.deleteLockSeat(
            orderUuid,
            () => {
              this.findResidue(showId);
            },
            (error) => {
              console.error(error);
              this.findResidue(showId);
            }
          );
        } else {
          this.findResidue(showId);
        }
      }
    });
  }

  findResidue(showId: number): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "show.findResidue",
      apiMethod: this.$api.goodApi.show.findResidue,
      params: [showId],
      expireInterval: 28800, //缓存时间 480 分钟
      randInterval: 300, //5分钟随机时间
      callback: ({ data }) => {
        this.SelectSeatModule.SET_surplus(data);
        // findResidue 接口有验证登录和取消预订单的功能，在验证登录并且成功取消预订单之后再渲染页面
        let selectSeat = this.$refs["select-seat"] as SelectSeat;
        if (selectSeat) {
          selectSeat.refresh();
        }
      },
    });
  }

  resetData(): void {
    this.SelectSeatModule.resetAllData();
  }

  goBackTo(): void {
    this.$router.go(-1);
  }

  /**
   * 锁座接口返回的 uuid
   */
  submit(data: order.AliSliderCaptchaDto): void {
    let selectedSeatIds = this.SelectSeatModule.selectedSeatIds;
    let surplus = this.SelectSeatModule.surplus;
    if (surplus > -1 && selectedSeatIds.length > surplus) {
      // 超出可购数量限制
      this.$toast({
        message: `单场限购${surplus}张`,
      });
    } else {
      if (this.AppSource == "harmonyOS") {
        if (!this.isClick) {
          this.isClick = true;
          window.setTimeout(() => {
            this.$api.orderApi.ticketOrder.lockSeatChoose(
              {
                aliSliderCaptchaDto: data,
                productId: this.SelectSeatModule.productId, // 产品 ID
                seatList: selectedSeatIds.map((seatId) => String(seatId)), // 座位 ID 列表
                sectionId: this.SelectSeatModule.sectionId, // 分区 ID
                showId: this.SelectSeatModule.showId, // 场次 ID
                distributionId: this.distributionChannelId,
                seriesId: this.distributionSeriesId,
              },
              ({ data }) => {
                this.SelectSeatModule.SET_submitLoading(false);
                // 将预订单 UUID 持久化，以便进入选座页时用来删除该预订单对应的锁定的座位
                this.DeleteLockSeatModule.SET_orderUuid_PERSIST(data);

                let params = {
                  theaterId: this.TheaterModule.theaterId,
                  uuid: this.DeleteLockSeatModule.orderUuid,
                };
                this.jsBridge("goConfirmOrder", params);
              },
              (error) => {
                this.SelectSeatModule.SET_submitLoading(false);
                this.$toast(`${error}`);
              }
            );
            this.isClick = false;
          }, 1000);
        }
      } else {
        this.$api.orderApi.ticketOrder.lockSeatChoose(
          {
            aliSliderCaptchaDto: data,
            productId: this.SelectSeatModule.productId, // 产品 ID
            seatList: selectedSeatIds.map((seatId) => String(seatId)), // 座位 ID 列表
            sectionId: this.SelectSeatModule.sectionId, // 分区 ID
            showId: this.SelectSeatModule.showId, // 场次 ID
            distributionId: this.distributionChannelId,
            seriesId: this.distributionSeriesId,
          },
          ({ data }) => {
            this.SelectSeatModule.SET_submitLoading(false);
            // 将预订单 UUID 持久化，以便进入选座页时用来删除该预订单对应的锁定的座位
            this.DeleteLockSeatModule.SET_orderUuid_PERSIST(data);
            this.goToOrderConfirm(data);
          },
          (error) => {
            this.SelectSeatModule.SET_submitLoading(false);
            this.$toast(`${error}`);
          }
        );
      }
    }
  }
}
