





































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Button, NavBar } from "vant";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
  },
})
export default class SelectArea extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: () => {
      return "POLY";
    },
  })
  productSource!: "POLY" | "DM" | "MY" | "MH" | "XY";

  areaMessage: good.SectionDto = {};
  areaMapSvg = "";
  availableSeatAmountMap: Record<string, good.ShowSectionDto> = {}; // 用于查询分区可售座位数和分区名称的 Map
  maoYanSectionIdMap: Record<string, string> = {}; // 猫眼项目用来转换 ID 的数据

  get showId(): number {
    return parseInt(this.$route.params.showId);
  }
  get productId(): string {
    return this.$route.params.productId;
  }

  // 刷新数据
  refreshData(): void {
    this.SelectSectionModule.SET_sectionIdWebCdnPathMap_PERSIST({});
    this.$api.goodApi.show.getSectionInfo(
      this.showId,
      {
        distributionChannelId: this.distributionChannelId,
        distributionSeriesId: this.distributionSeriesId,
      },
      ({ data }) => {
        this.areaMessage = data;
        let showMap = this.areaMessage.showMap;
        this.areaMapSvg = showMap ? showMap.mapSvg || "" : "";
        let showSectionDtos = data.showSectionDtos;
        if (showSectionDtos && showSectionDtos.length > 0) {
          let sectionIdWebCdnPathMap: Record<string, string> = {};
          let availableSeatAmountMap: Record<string, good.ShowSectionDto> = {}; // 分区可售座位数 Map
          showSectionDtos.forEach((sectionDto) => {
            let sectionId = sectionDto.sectionId
              ? String(sectionDto.sectionId)
              : "";
            let webCdnPath = sectionDto.webCdnPath;
            if (sectionId) {
              availableSeatAmountMap[sectionId] = sectionDto;
              if (webCdnPath) {
                sectionIdWebCdnPathMap[sectionId] = webCdnPath;
              }
            }
          });
          this.availableSeatAmountMap = availableSeatAmountMap;
          this.SelectSectionModule.SET_sectionIdWebCdnPathMap_PERSIST(
            sectionIdWebCdnPathMap
          );
          if (this.productSource == "MY") {
            // 猫眼项目，需要对分区 ID 进行转换
            showSectionDtos.forEach((sectionDto) => {
              let sectionId = sectionDto.sectionId
                ? String(sectionDto.sectionId)
                : "";
              let mapCode = sectionDto.mapCode;
              if (sectionId && mapCode) {
                this.maoYanSectionIdMap[mapCode] = sectionId;
              }
            });
          }
        }
        // 将 svg 居中
        this.$nextTick(() => {
          const svgBox = this.$refs.svgBox as Element;
          const svgItem = svgBox ? svgBox.getElementsByTagName("svg")[0] : null;
          if (svgBox && svgItem) {
            const screenWidth = window.document.documentElement.clientWidth; // 屏幕宽度
            const svgWidth = svgItem.clientWidth; // svg 的宽度
            if (svgWidth > screenWidth) {
              svgBox.scrollLeft = (svgWidth - screenWidth) / 2;
            }
          }
        });
      }
    );
  }

  // 选择区域
  selectSection(event: {
    target: { id: string; nodeName: string; dataset: { status: string } };
  }): void {
    if (event.target.nodeName != "image") {
      let sectionId = this.getSectionIdByTargetId(event.target.id);
      if (sectionId) {
        let showSectionDto: good.ShowSectionDto =
          this.availableSeatAmountMap[sectionId]; // 分区可售座位数
        let availableSeatAmount = showSectionDto
          ? showSectionDto.availableSeatAmount
          : 0;
        if (!availableSeatAmount || availableSeatAmount < 0) {
          this.$toast({
            message: `${
              showSectionDto ? showSectionDto.cname || "该" : "该"
            }区域已没有可销售的座位，看看其他区域`,
          });
          return;
        }
        this.$emit("select-section", {
          showId: this.showId,
          productId: this.productId,
          sectionId,
        });
      }
    }
  }

  /**
   * 根据 SVG 上的 id 解析出分区 ID
   * 猫眼的分区 ID 需要转换一下
   */
  getSectionIdByTargetId(targetId?: string): string {
    if (!targetId) {
      return "";
    }
    return this.productSource == "MY"
      ? this.maoYanSectionIdMap[targetId] || targetId
      : targetId;
  }
}
